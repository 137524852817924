import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import cookieSnackbarStyle from "assets/jss/material-kit-react/views/componentsSections/cookieSnackbarStyle.jsx";
import Snackbar from "@material-ui/core/Snackbar";
import Button from "@material-ui/core/Button";
import { withCookies, Cookies } from 'react-cookie';
import {instanceOf} from "prop-types";

class CookieSnackbar extends React.Component {

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    state = {
        cookieConsentGiven: false,
    }

    constructor(props) {
        super(props);

        const { cookies } = props;
        this.state = {
            cookieConsentGiven: cookies.get('cookieConsentGiven') === "true"
        };
    }

    render() {
        return <Snackbar
            open={this.state.cookieConsentGiven !== true}
            message="Diese Website verwendet Cookies"
            key="cookieConsent"
            action={<Button color="secondary" size="small" onClick={this.handleConsentGiven}>OK</Button>}
        />;
    }

    handleConsentGiven = () => {
        const { cookies } = this.props;
        cookies.set('cookieConsentGiven', "true", { path: '/' });
        this.setState({cookieConsentGiven: true });
    }
}



export default withStyles(cookieSnackbarStyle)(withCookies(CookieSnackbar));